export default {
  active: [
    {
      width: '40px',
      value: 'edit',
      align: 'center',
      colModifiers:[
        { type: 'edit' }
      ],
      editPath: 'suppliers/edit/'
    },
    { text: 'Поставщик', value: 'name' },
    { text: 'Тип контракта', value: 'contract_type' },
    { text: 'Наши РЦ', value: 'werks' },
    { text: 'Статус менялся', value: 'status_changed_at' }
  ],
  blocked: [
    {
      width: '40px',
      value: 'edit',
      align: 'center',
      colModifiers:[
        { type: 'edit' }
      ],
      editPath: 'suppliers/edit/'
    },
    { text: 'Поставщик', value: 'name' },
    { text: 'Тип контракта', value: 'contract_type' },
    { text: 'Дата блокировки', value: 'status_changed_at' },
    { text: 'Комментарий к блокировке', value: 'disabled_comment' }
  ]
}
